import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";
import "../styles/style.css";
import "../styles/Header.css";
import Login from "../assets/Login.svg";
import Search from "../assets/search.svg";
import Globe from "../assets/globe.svg";
import Cart from "../assets/cart.svg";
import CartDropdown from "./CartDropdown";
import { Dropdown } from "react-bootstrap";
import { useUserInfoStore } from "../stores/UserInfo";
import {
  useGetAccountInfoQuery,
  useUserLogout,
} from "../api/Customer/Customer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPerson,
  faSignOut,
  faSignIn,
} from "@fortawesome/free-solid-svg-icons";
import { useCartStore } from "../stores/Cart";
import { useListCartQuery } from "../hooks/Cart";

const Header = () => {
  const setUserInfo = useUserInfoStore((state) => state.setUserInfo);
  const { data: userData, isLoading, refetch } = useGetAccountInfoQuery();
  const userLogout = useUserLogout();
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);
  const cartItemsCount = useCartStore((state) => state.cart.length);
  const { data: cartItems, isLoading: isCartLoading } = useListCartQuery();

  useEffect(() => {
    if (isCartLoading || !cartItems) return;

    if (cartItems) {
      useCartStore.setState({ cart: cartItems });
    }
  }, [cartItems, isCartLoading]);

  useEffect(() => {
    if (!isLoading && userData) {
      setUserInfo(userData);
    }
  }, [userData, isLoading]);

  useEffect(() => {
    // Add Google Tag Manager script to the document head
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-16760840490";
    script1.onload = () => {
      console.log('Google Analytics script loaded');
    };
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16760840490');
      console.log('Google Analytics initialized');
    `;
    document.head.appendChild(script2);
  }, []);

  useEffect(() => {
    const toggleScrolled = () => {
      const selectBody = document.querySelector("body");
      const selectHeader = document.querySelector("#header");
      if (
        !selectHeader?.classList.contains("scroll-up-sticky") &&
        !selectHeader?.classList.contains("sticky-top") &&
        !selectHeader?.classList.contains("fixed-top")
      )
        return;
      window.scrollY > 100
        ? selectBody?.classList.add("scrolled")
        : selectBody?.classList.remove("scrolled");
    };

    document.addEventListener("scroll", toggleScrolled);
    window.addEventListener("load", toggleScrolled);

    // Mobile nav toggle
    const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");
    const mobileNavToggle = () => {
      document.querySelector("body")?.classList.toggle("mobile-nav-active");
      setIsMobileNavActive((prev) => !prev);
      mobileNavToggleBtn?.classList.toggle("bi-x");
      mobileNavToggleBtn?.classList.toggle("bi-list");
    };
    mobileNavToggleBtn?.addEventListener("click", mobileNavToggle);

    // Hide mobile nav on same-page/hash links
    document.querySelectorAll("#navmenu a").forEach((navmenu) => {
      navmenu.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToggle();
        }
      });
    });

    // Toggle mobile nav dropdowns
    document
      .querySelectorAll(".navmenu .toggle-dropdown")
      .forEach((navmenu) => {
        navmenu.addEventListener("click", (e) => {
          e.preventDefault();
          const parent = (e.target as HTMLElement).parentNode as HTMLElement;
          parent.classList.toggle("active");
          (parent.nextElementSibling as HTMLElement).classList.toggle(
            "dropdown-active"
          );
          e.stopImmediatePropagation();
        });
      });

    return () => {
      // Clean up event listeners
      document.removeEventListener("scroll", toggleScrolled);
      mobileNavToggleBtn?.removeEventListener("click", mobileNavToggle);
    };
  }, []);

  return (
    <header id="header" className="header sticky-top">
      <div className="topbar d-flex align-items-center">
        <div className="container d-flex justify-content-end">
          {userData && (
            <>
              <Link
                to="#logout"
                className="text-black"
                onClick={userLogout}
                style={{ display: "flex" }}
              >
                <div className="topbar-item">
                  <FontAwesomeIcon
                    icon={faSignOut}
                    style={{
                      color: "rgba(0,0,0,1)",
                      marginRight: 5,
                      marginBottom: 2,
                    }}
                  />
                  <span>登出</span>
                </div>
              </Link>
              <Link
                to="/member/dashboard"
                className="text-black"
                style={{ display: "flex" }}
              >
                <div className="topbar-item">
                  <FontAwesomeIcon
                    icon={faPerson}
                    style={{
                      color: "rgba(0,0,0,1)",
                      marginRight: 5,
                      marginBottom: 2,
                    }}
                  />
                  <span>會員</span>
                </div>
              </Link>
            </>
          )}
          {!userData && (
            <Link
              to="/login"
              className="text-black"
              style={{ display: "flex" }}
            >
              <div className="topbar-item">
                <FontAwesomeIcon
                  icon={faSignIn}
                  style={{
                    color: "rgba(0,0,0,1)",
                    marginRight: 5,
                    marginBottom: 2,
                  }}
                />
                <span>登入</span>
              </div>
            </Link>
          )}
          <div className="topbar-divider"></div>
          <div className="topbar-item">
            <img src={Search} alt="search" />
            <span>搜尋</span>
          </div>
          <div className="topbar-divider"></div>
          <div className="topbar-item">
            <img src={Globe} alt="globe" />
            <span style={{ marginLeft: "4px" }}>台灣</span>
          </div>
        </div>
      </div>

      <div className="branding d-flex align-items-center">
        <div className="container position-relative d-flex align-items-center justify-content-between">
          <Link to="/home" className="logo d-flex align-items-center me-auto">
            <img src={Logo} alt="Logo" width={91} />
          </Link>

          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <Link to="/why-choose-us">為什麼選擇Big-O</Link>
              </li>
              <li className="dropdown">
                <Link to="">
                  <span>核心產品</span>{" "}
                  <i className="bi bi-chevron-down toggle-dropdown"></i>
                </Link>
                <ul>
                  <li className="dropdown">
                    <Link to="/micro-business-solutions-feature">
                      {/* <div className='dropdown-item'> */}
                      <span>AI永續智能電商平台(AISSC)</span>
                      <i className="bi bi-chevron-right toggle-dropdown"></i>
                      {/* </div> */}
                    </Link>
                    <ul>
                      <li>
                        <Link to="/micro-business-solutions-feature">
                          特色總覽
                        </Link>
                      </li>
                      <li>
                        <Link to="/pricing">方案費用</Link>
                      </li>
                      <li>
                        <Link to="/micro-business-solutions-store">
                          彈性客製
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      to="/"
                      className="disabled-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      AI永續賦能課程平台(AISEC)
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/"
                      className="disabled-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      AI精準健康醫療平台(AIPHC)
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="">教育自媒體解決方案</Link>
                  </li>
                  <li>
                    <Link to="">整合式醫療服務解決方案</Link>
                  </li>
                  <li>
                    <Link to="">智慧建築與設計應用解決方案</Link>
                  </li>
                  <li>
                    <Link to="">金融實戰應用解決方案</Link>
                  </li> */}
                </ul>
              </li>
              <li>
                <Link to="/pricing">方案費用</Link>
              </li>
              <li className="dropdown">
                <a href="">
                  <span>最新案例</span>{" "}
                  <i className="bi bi-chevron-down toggle-dropdown"></i>
                </a>
                <ul>
                  <li>
                    <a href="/leading-brand">網路開店案例</a>
                  </li>
                  {/*<li><a href="">教育自媒體案例 </a></li>*/}
                  {/*<li><a href="">整合式醫療應用案例</a></li>*/}
                  {/*<li><a href="">智慧建築與設計案例</a></li>*/}
                  {/*<li><a href="">金融實戰應用案例 </a></li>*/}
                </ul>
              </li>
              <li className="dropdown">
                <a href="">
                  <span>策略聯盟</span>{" "}
                  <i className="bi bi-chevron-down toggle-dropdown"></i>
                </a>
                <ul>
                  <li>
                    <a href="/business-plan">異業結盟計畫</a>
                  </li>
                  <li>
                    <a href="/partner-ship">尋找策略合作夥伴</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="">
                  <span>資源與活動</span>{" "}
                  <i className="bi bi-chevron-down toggle-dropdown"></i>
                </a>
                <ul>
                  <li>
                    <a href="/resources">白皮書及指南</a>
                  </li>
                  {/*<li><a href="">網路研討會</a></li>*/}
                  {/*<li><a href="">活動</a></li>*/}
                </ul>
              </li>
              <li className="dropdown">
                <a href="">
                  <span>新聞中心</span>{" "}
                  <i className="bi bi-chevron-down toggle-dropdown"></i>
                </a>
                <ul>
                  <li>
                    <a href="/news-highlights">展翅高飛焦點</a>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/blog">雲端數位智慧學院</Link>
              </li>
              <li>
                <Link to="/contact">立即聯繫</Link>
              </li>
              {/* display for desktop */}
              <Dropdown align="end" className="d-none d-xl-block">
                <Dropdown.Toggle
                  as="div"
                  id="dropdownMenuButton"
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <img src={Cart} alt="cart" />
                  {cartItemsCount > 0 && (
                    <span
                      style={{
                        position: "absolute",
                        top: "-8px",
                        right: "-8px",
                        background: "#ff4444",
                        color: "white",
                        borderRadius: "50%",
                        padding: "2px 6px",
                        fontSize: "12px",
                        minWidth: "20px",
                        textAlign: "center",
                      }}
                    >
                      {cartItemsCount}
                    </span>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="p-3"
                  style={{
                    width: "350px",
                    border: "1px solid rgba(0,0,0,0.1)",
                    boxShadow:
                      "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important",
                    borderRadius: 0,
                  }}
                >
                  <CartDropdown />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
            {/* display for mobile */}
            <div className="d-flex align-items-center">
              <Dropdown
                align="end"
                className={`d-xl-none ${isMobileNavActive ? "d-none" : ""}`}
                id="mobile-cart"
              >
                <Dropdown.Toggle
                  as="div"
                  id="dropdownMenuButton"
                  style={{ position: "relative" }}
                >
                  <img src={Cart} alt="cart" />
                  {cartItemsCount > 0 && (
                    <span
                      style={{
                        position: "absolute",
                        top: "-8px",
                        right: "-8px",
                        background: "#ff4444",
                        color: "white",
                        borderRadius: "50%",
                        padding: "2px 6px",
                        fontSize: "12px",
                        minWidth: "20px",
                        textAlign: "center",
                      }}
                    >
                      {cartItemsCount}
                    </span>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="p-3"
                  style={{
                    width: "350px",
                    border: "1px solid rgba(0,0,0,0.1)",
                    boxShadow:
                      "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important",
                    borderRadius: 0,
                  }}
                >
                  <CartDropdown />
                </Dropdown.Menu>
              </Dropdown>
              <i
                className="mobile-nav-toggle d-xl-none bi-list"
                style={{ marginLeft: 10 }}
              ></i>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
