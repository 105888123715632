import React from "react";
import "../styles/Timeline.css";
import logo from "../assets/logo.png";
import Tcloud from "../assets/whychooseus/tcloud.png";
import ECPayLogo from "../assets/ecpay.png";
import TBLC from "../assets/TBLC.jpg";
import Certificate from "../assets/certificate.png";
const Timeline = () => {
  const milestones = [
    {
      date: "2023.11",
      description: "公司成立",
      logo: logo,
    },
    {
      date: "2023.12",
      description:
        "榮獲數位發展部數位產業署 「引領中小微型企業數位轉型戰略攻頂計畫」 雲市集遴選優質資服廠商",
      logo: Tcloud,
    },
    {
      date: "2024.01",
      description: "公司攜手綠界金流打造多元支付環境",
      logo: ECPayLogo,
    },
    {
      date: "2024.03",
      description: "公司加入「台灣企業領袖交流會」",
      logo: TBLC,
    },
    {
      date: "2024.08",
      description: "公司成為「中華民國資訊軟體協會」企業會員",
      logo: Certificate,
    },
  ];

  return (
    <section className="timeline-section">
      <div className="container">
        <h2 className="text-center">關鍵里程碑</h2>
        <div className="timeline">
          {milestones.map((milestone, index) => (
            <div className="timeline-item" key={index}>
              <div className="timeline-content top">
                {milestone.logo && (
                  <img
                    src={milestone.logo}
                    alt={milestone.description}
                    className="timeline-logo"
                  />
                )}
              </div>
              <div className="timeline-date">
                <div className="date-bar">{milestone.date}</div>
              </div>
              <div className="timeline-content bottom">
                {milestone.description && (
                  <p className="title-description">{milestone.description}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Timeline;
