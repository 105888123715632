import axios from "axios";
import { Product } from "../Product/Product";

const API_URL = process.env.REACT_APP_API_URL;
const BASE_URL = `${API_URL}/cart-items`;

export interface ListCartItemResponse {
  id: number;
  productId: number;
  quantity: number;
  createdAt: string;
  updatedAt: string;
  customerId: number;
  product: Product;
}

/**
 * list user's cart items
 * @param {string} userToken - user token
 * @returns {ListCartItemResponse[]} - list of cart items
 */
export async function listCart({ userToken }: { userToken: string }) {
  if (!userToken) {
    throw new Error("user_token can not be empty");
  }
  const res = await axios.get<ListCartItemResponse[]>(BASE_URL, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return res.data;
}

export type ModifyCartItem = {
  productId: number;
  quantity: number;
};

export type ModifyCartItemResponse = {
  id: number;
  customerId: number;
  productId: number;
  quantity: number;
  createdAt: Date;
  updatedAt: Date;
};

/**
 * add item to cart
 * @param {ModifyCartItem} item - product id
 * @param {number} item.productId - product id
 * @param {number} item.quantity - quantity of product
 * @param {string} userToken - user token
 * @returns {ModifyCartItemResponse} - cart item
 */
export async function addItemToCart({
  productId,
  quantity,
  userToken,
}: ModifyCartItem & { userToken: string }) {
  if (!userToken) {
    throw new Error("user_token can not be empty");
  }
  const res = await axios.post<ModifyCartItemResponse>(
    BASE_URL,
    {
      productId,
      quantity,
    },
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
  return res.data;
}

export type UpdateCartItem = {
  cartItemId: number;
  productId: number;
  quantity: number;
};
/**
 * update item quantity
 * @param {ModifyCartItem} item - product id
 * @param {number} item.cartItemId - cartItemId id
 * @param {number} item.productId - product id
 * @param {number} item.quantity - quantity of product
 * @param {string} userToken - user token
 * @returns {ModifyCartItemResponse} - cart item
 */
export async function updateCartItem({
  cartItemId,
  productId,
  quantity,
  userToken,
}: UpdateCartItem & { userToken: string }) {
  if (!userToken) {
    throw new Error("user_token can not be empty");
  }
  const res = await axios.patch<ModifyCartItemResponse>(
    `${BASE_URL}/${cartItemId}`,
    { quantity, productId },
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
  return res.data;
}

/**
 * remove item from cart
 * @param {number} cartItemIds - product id
 * @param {string} userToken - user token
 * @returns {ModifyCartItemResponse} - cart item
 */
export async function removeCartItem({
  cartItemIds,
  userToken,
}: {
  cartItemIds: number;
  userToken: string;
}) {
  if (!userToken) {
    throw new Error("user_token can not be empty");
  }
  const res = await axios.delete<ModifyCartItemResponse>(
    `${BASE_URL}/${cartItemIds}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
  return res.data;
}

/**
 * clear cart
 * @param {string} userToken - user token
 * @returns {ModifyCartItemResponse} - cart item
 */
export async function clearCart({ userToken }: { userToken: string }) {
  if (!userToken) {
    throw new Error("user_token can not be empty");
  }
  const res = await axios.delete(BASE_URL, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return res.data;
}

/**
 * 批量刪除購物車商品
 * @param {number[]} cartItemIds - 要刪除的商品 ID 列表
 */
export async function batchRemoveCartItems({
  cartItemIds,
  userToken,
}: {
  cartItemIds: number[];
  userToken: string;
}) {
  if (!userToken) {
    throw new Error("user_token can not be empty");
  }

  // 使用 Promise.all 並行處理多個刪除請求
  await Promise.all(
    cartItemIds.map((cartItemId) =>
      axios.delete<ModifyCartItemResponse>(`${BASE_URL}/${cartItemId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
    )
  );

  return { success: true, count: cartItemIds.length };
}
