import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useUserInfoStore } from "./../../stores/UserInfo";
import { useTokenStore } from "./../../stores/Token";

// apis

export async function userLogin({
  type = "phone",
  email,
  phone,
  password,
  merchantId,
}: {
  type: "phone" | "email";
  email?: string;
  phone?: string;
  password: string;
  merchantId: number;
}) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await axios
    .post(`${API_URL}/customers/signin`, {
      type,
      email,
      phone,
      password,
      merchantId,
    })
    .catch((e) => {
      console.log(e);
      throw e;
    });

  return res.data;
}

export async function userCreateResetPasswordToken({
  type = "phone",
  phone,
  email,
  merchantId,
}: {
  merchantId: number;
  type: "phone" | "email";
  phone: string;
  email: string;
}) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await axios.post<"">(
    `${API_URL}/customers/reset-password-token`,
    {
      type,
      phone,
      email,
      merchantId,
    }
  );

  return res.data;
}

export async function userResetPassword({
  type = "phone",
  phone,
  email,
  password,
  token,
  merchantId,
}: {
  type: "phone" | "email";
  phone: string;
  email: string;
  password: string;
  token: string;
  merchantId: number;
}) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await axios.post(`${API_URL}/customers/reset-password`, {
    type,
    phone,
    email,
    password,
    token,
    merchantId,
  });

  return res.data;
}

export async function editCustomerProfile(
  editCustomerPayload: {
    phone: string;
    email: string;
    deliveryAddress: string;
    deliveryPhone: string;
    subscribedEmail: boolean;
    subscribedSMS: boolean;
  },
  token: string
) {
  const API_URL = process.env.REACT_APP_API_URL;
  if (token === "") {
    throw new Error("user_token can not be empty");
  }
  const res = await axios.patch(
    `${API_URL}/customers/current`,
    editCustomerPayload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
}

export async function getCustomerProfile(token: string) {
  const API_URL = process.env.REACT_APP_API_URL;
  if (token === "") {
    throw new Error("user_token can not be empty");
  }
  const res = await axios.get(`${API_URL}/customers/current`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
}

export async function createVerifyPhoneToken({ phone }: { phone: string }) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await axios.post(
    `${API_URL}/customers/create-verify-phone-token`,
    {
      phone,
      merchantId: 1,
    }
  );

  return res.data;
}

export async function checkVerifyPhoneToken({
  phone,
  token,
}: {
  phone: string;
  token: string;
}) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await axios.post(
    `${API_URL}/customers/check-verify-phone-token`,
    {
      phone,
      token,
      merchantId: 1,
    }
  );

  return res.data;
}

export async function userSignup({
  type = "phone",
  email,
  phone,
  phoneVerificationTokens,
  password,
  merchantId,
  name,
}: {
  type: "phone" | "email";
  email?: string;
  phone?: string;
  phoneVerificationTokens?: string;
  password: string;
  merchantId: number;
  name?: string;
}) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await axios.post(`${API_URL}/customers`, {
    type,
    email,
    phone,
    phoneVerificationTokens,
    password,
    merchantId,
    name,
  });

  return res.data;
}

// react query

export function useUserLoginMutation() {
  const navigate = useNavigate();
  const setUserInfo = useUserInfoStore((state) => state.setUserInfo);
  const setToken = useTokenStore((state) => state.setValue);
  return useMutation({
    mutationFn: (loginPayload: { phone: string; password: string }) =>
      userLogin({
        ...loginPayload,
        type: "phone",
        merchantId: 1,
      }),
    onMutate: () => {},
    onSuccess: (data) => {
      if (!data) return;
      setToken(data.token);
      setUserInfo(data.customer);
      navigate("/home");
    },
    onError: (error) => {
      throw error;
    },
  });
}

export function useCreateResetPasswordTokenMutation() {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (payload: { phone: string }) =>
      userCreateResetPasswordToken({
        ...payload,
        type: "phone",
        email: "",
        merchantId: 1,
      }),
    onMutate: () => {},
    onSuccess: (data) => {
      navigate("/reset-password");
    },
    onError: (error) => {
      console.log(error);
      throw error;
    },
  });
}

export function useResetPasswordMutation() {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (payload: { phone: string; password: string; token: string }) =>
      userResetPassword({
        ...payload,
        type: "phone",
        email: "",
        merchantId: 1,
      }),
    onMutate: () => {},
    onSuccess: (data) => {
      navigate("/login");
    },
    onError: (error) => {
      console.log(error);
    },
  });
}

export function useGetAccountInfoQuery() {
  const token = useTokenStore((state) => state.value);

  return useQuery({
    queryKey: ["customer-account", "get", token],
    queryFn: () => getCustomerProfile(token),
    enabled: !!token,
  });
}

export function useUpdateProfileMutation() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const token = useTokenStore((state) => state.value);

  return useMutation({
    mutationFn: (editCustomerPayload: {
      phone: string;
      email: string;
      deliveryAddress: string;
      deliveryPhone: string;
      subscribedEmail: boolean;
      subscribedSMS: boolean;
    }) => editCustomerProfile(editCustomerPayload, token),
    onMutate: () => {},
    onSuccess: () => {
      queryClient.setQueryData(["customer-account", "get"], (oldData: any) => ({
        ...oldData,
      }));
      navigate("/");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["customer-account", "get"],
      });
    },
  });
}

export function useUserLogout() {
  const navigate = useNavigate();
  const resetUserInfo = useUserInfoStore((state) => state.resetUserInfo);
  const setToken = useTokenStore((state) => state.setValue);

  return () => {
    setToken("");
    resetUserInfo();

    navigate("/");
  };
}

export function useCreateVerifyPhoneTokenMutation() {
  return useMutation({
    mutationFn: (payload: { phone: string }) => createVerifyPhoneToken(payload),
    onMutate: () => {},
    onSuccess: () => {},
    onError: (error) => {
      console.log(error);
    },
  });
}

export function useCheckVerifyPhoneTokenMutation() {
  return useMutation({
    mutationFn: (payload: { phone: string; token: string }) =>
      checkVerifyPhoneToken(payload),
    onMutate: () => {},
    onSuccess: () => {},
    onError: (error) => {
      console.log(error);
    },
  });
}

export function useUserSignUpMutation() {
  const navigate = useNavigate();
  const setUserInfo = useUserInfoStore((state) => state.setUserInfo);
  const setToken = useTokenStore((state) => state.setValue);

  return useMutation({
    mutationFn: (loginPayload: {
      phone: string;
      email: string;
      phoneVerificationTokens: string;
      password: string;
      name?: string;
    }) =>
      userSignup({
        ...loginPayload,
        type: "phone",
        merchantId: 1,
      }),
    onMutate: () => {},
    onSuccess: (data) => {
      if (!data) return;
      setToken(data.token);
      setUserInfo(data.customer);
      navigate("/home");
    },
    onError: (error) => {
      console.log(error);
    },
  });
}
