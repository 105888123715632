import axios from "axios";

// Order status enum
export type OrderStatus =
  | "open"
  | "processing"
  | "unshipped"
  | "shipped"
  | "cancelled";
export type ChargeType = "monthly" | "annual" | "buyout" | "monthly_and_annual";
export type PaymentType = "newebpay" | "cod";
export type PaymentStatus = "pending" | "paid" | "failed";
export type DeliveryStatus = "pending" | "delivered" | "failed";
export type DeliveryType = "pickup" | "newebpay_delivery";

export interface OrderItem {
  id: number;
  orderId: number;
  productId: number;
  quantity: number;
  // 下單時的一些商品資訊
  productName: string;
  productPrice: number;
  productOriginalPrice: number;
  productDescription: string;
  productImage: string;
}

export interface Payment {
  id: number;
  orderId: number;
  chargeType: ChargeType;
  paymentType: PaymentType;
  status: PaymentStatus;
  amount: number;
  createdAt: string;
  paidAt: string;
}
// Order interface
export interface Order {
  id: number;
  displayId: string; // 顯示用編號
  customerId: number;
  merchantId: number;
  status: OrderStatus;
  totalAmountWithoutTax: number;
  totalAmountWithTax: number;
  taxRate: number;
  totalAnnualAmountWithoutTax: number;
  totalAnnualAmountWithTax: number;
  totalMonthlyAmountWithoutTax: number;
  totalMonthlyAmountWithTax: number;
  chargeType: ChargeType;
  paymentType: PaymentType;

  // 送貨資訊
  deliveryAddress: string;
  deliveryPhone: string;
  deliveryName: string;
  deliveryEmail: string;
  deliveryCompany: string;
  deliveryTrackingNumber: string;
  deliveryStatus: DeliveryStatus;
  deliveryFee: number;
  deliveryType: DeliveryType;

  // 聯絡資訊
  contactName: string;
  contactPhone: string;
  contactEmail: string;

  newebpayChargeToken: string;
  comment: string;
  reference: string;

  payments: Payment[];

  createdAt: Date;
  updatedAt: Date;

  orderItems: OrderItem[];
}

export interface OrderListResponse {
  orders: Order[];
  totalCount: number;
}

// 獲取訂單列表的函數
export const getCustomerOrders = async (
  limit: number = 10,
  offset: number = 0,
  token: string
): Promise<OrderListResponse> => {
  const API_URL = process.env.REACT_APP_API_URL;
  if (token === "") {
    throw new Error("user_token can not be empty");
  }

  const response = await axios.get<OrderListResponse>(
    `${API_URL}/orders/customer`,
    {
      params: {
        limit,
        offset,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export const getCustomerOrderDetail = async (
  id: number,
  token: string
): Promise<Order> => {
  const API_URL = process.env.REACT_APP_API_URL;

  if (token === "") {
    throw new Error("user_token can not be empty");
  }

  const response = await axios.get<Order>(
    `${API_URL}/orders/customer/order/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
