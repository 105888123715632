import { useQuery } from "@tanstack/react-query";
import {
  findProductsByCategoryIdAndPaymentType,
  PaymentType,
} from "../api/Product/Product";

export function useFindProductsByCategoryIdAndPaymentTypeQuery(
  mainCategoryId: number,
  paymentType: PaymentType
) {
  return useQuery({
    queryKey: [
      "products",
      "mainCategoryId",
      mainCategoryId,
      "paymentType",
      paymentType,
    ],
    queryFn: () =>
      findProductsByCategoryIdAndPaymentType(mainCategoryId, paymentType),
  });
}
