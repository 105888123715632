import { useCartStore } from "../stores/Cart";
import {
  useAddItemToCartMutation,
  useUpdateCartItemMutation,
  useRemoveCartItemMutation,
  useClearCartMutation,
} from "./Cart";
import { useTokenStore } from "../stores/Token";
import { useState } from "react";

// Define the ModifyCartItem type locally since it's not exported from useCart
export type ModifyCartItem = {
  productId: number;
  quantity: number;
};

/**
 * A shared hook that manages cart operations and determines which API to call
 * based on whether the product already exists in the cart
 */
export function useCartManagement() {
  // Get cart state from Zustand store
  const { cart } = useCartStore();
  const userToken = useTokenStore((state) => state.value);
  const [showLoginModal, setShowLoginModal] = useState(false);

  // Initialize mutations
  const addItemMutation = useAddItemToCartMutation();
  const updateItemMutation = useUpdateCartItemMutation();
  const removeItemMutation = useRemoveCartItemMutation();
  const clearCartMutation = useClearCartMutation();

  // 檢查用戶是否已登入
  const isLoggedIn = !!userToken;

  /**
   * Add or update a product in the cart
   * @param {ModifyCartItem} item - The item to add or update
   * @returns {Promise} - The result of the mutation
   */
  const addToCart = async (item: ModifyCartItem) => {
    // 如果用戶未登入，顯示登入提示
    if (!isLoggedIn) {
      setShowLoginModal(true);
      return null;
    }

    const { productId, quantity } = item;
    // Check if the product already exists in the cart
    const existingItem = cart.find(
      (cartItem) => cartItem.productId === productId
    );

    if (existingItem) {
      // If the product exists, update its quantity
      return updateItemMutation.mutateAsync({
        cartItemId: existingItem.id,
        productId,
        quantity: existingItem.quantity + quantity,
      });
    } else {
      // If the product doesn't exist, add it to the cart
      return addItemMutation.mutateAsync({ productId, quantity });
    }
  };

  /**
   * Remove a product from the cart
   * @param {number} productId - The ID of the product to remove
   * @returns {Promise} - The result of the mutation
   */
  const removeFromCart = async (productId: number) => {
    // 如果用戶未登入，顯示登入提示
    if (!isLoggedIn) {
      setShowLoginModal(true);
      return null;
    }

    return removeItemMutation.mutateAsync(productId);
  };

  /**
   * Clear all items from the cart
   * @returns {Promise} - The result of the mutation
   */
  const clearCart = async () => {
    // 如果用戶未登入，顯示登入提示
    if (!isLoggedIn) {
      setShowLoginModal(true);
      return null;
    }

    return clearCartMutation.mutateAsync();
  };

  return {
    cart,
    addToCart,
    removeFromCart,
    clearCart,
    isAddingToCart: addItemMutation.isPending || updateItemMutation.isPending,
    isRemovingFromCart: removeItemMutation.isPending,
    isClearingCart: clearCartMutation.isPending,
    addToCartError: addItemMutation.error || updateItemMutation.error,
    removeFromCartError: removeItemMutation.error,
    clearCartError: clearCartMutation.error,
    isLoggedIn,
    showLoginModal,
    setShowLoginModal,
  };
}
