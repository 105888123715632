// PricingSwitcherTwo.jsx
import React, { useState } from "react";
import Tooltip from "./Tooltip";
import "../styles/PricingSwitcher.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

const PricingSwitcherTwo = ({ pricingData }) => {
  const [plan, setPlan] = useState("monthly");

  if (!pricingData || !pricingData[plan]) {
    return <div className="text-center">方案載入中...</div>;
  }

  return (
    <div className="pricing-switcher">
      <div className="text-center mb-5">
        <div className="toggleWrapper">
          <input
            type="checkbox"
            id="pricing-toggle-two"
            className="dn"
            checked={plan === "annual"}
            onChange={() => setPlan(plan === "monthly" ? "annual" : "monthly")}
          />
          <label htmlFor="pricing-toggle-two" className="toggle">
            <span className="toggle__handler"></span>
            <span className="toggle__label--off">平均每月成本</span>
            <span className="toggle__label--on">年繳價格</span>
          </label>
        </div>
      </div>

      <div className="row">
        {pricingData[plan].map((card, index) => (
          <div className="col-lg-3 col-12" key={index}>
            <div
              className={`card pricing-card ${
                card.title === "Professional" ? "recommended" : ""
              }`}
            >
              {index === 1 && (
                <div className="recommended card-header">
                  <span className="badge">推薦</span>
                </div>
              )}
              <div className="card-body">
                <h5 className="card-title">{card.title}</h5>
                <p className="card-description">{card.description}</p>

                <div className="card-price-container">
                  <span className="currency">NT$</span>
                  <span className="price">{card.price}</span>
                  <Tooltip content={card.tooltipContent}>
                    <span className="info-icon">i</span>
                  </Tooltip>
                </div>
                <div className="text-center">
                  {card.originalPrice && (
                    <p className="card-original-price">
                      原價 NT$ <span>{card.originalPrice}</span>/年
                    </p>
                  )}
                </div>
                <div className="text-center">
                  {card.annualPrice && (
                    <p className="card-annual-price">
                      NT$ <span>{card.annualPrice}</span>/年
                    </p>
                  )}
                </div>
                <button className="btn btn-primary mt-3">
                  <a href="/contact">請洽顧問聯繫</a>
                </button>
                <div
                  className="list-unstyled mt-4"
                  dangerouslySetInnerHTML={{ __html: card.features }}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingSwitcherTwo;
