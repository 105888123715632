import { useQuery } from "@tanstack/react-query";
import {
  getCustomerOrderDetail,
  getCustomerOrders,
  Order,
} from "../api/Order/Order";
import { useTokenStore } from "../stores/Token";
export type OrderListResponse = {
  orders: Order[];
  totalCount: number;
};

export function useGetCustomerOrdersQuery(
  limit: number = 10,
  offset: number = 0
) {
  const token = useTokenStore((state) => state.value);
  return useQuery({
    queryKey: ["customerOrders", limit, offset],
    queryFn: () => getCustomerOrders(limit, offset, token),
  });
}

export function useGetCustomerOrderDetailQuery(id: number) {
  const token = useTokenStore((state) => state.value);
  return useQuery({
    queryKey: ["customerOrderDetail", id],
    queryFn: () => getCustomerOrderDetail(id, token),
    enabled: !!id,
  });
}
