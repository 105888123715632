import { useState } from "react";
import { Button, Card, Table, Badge, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useGetCustomerOrdersQuery } from "../hooks/Order";
import Pagination from "./Pagination";

export function MemberOrder() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const navigate = useNavigate();

  const { data, isLoading, isError } = useGetCustomerOrdersQuery(
    pageSize,
    (currentPage - 1) * pageSize
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleOrderClick = (orderId: number) => {
    navigate(`/member/order/${orderId}`);
  };

  // 訂單狀態對應的中文名稱和樣式
  const getStatusDisplay = (status: string) => {
    switch (status) {
      case "open":
        return { text: "待處理", variant: "warning" };
      case "processing":
        return { text: "處理中", variant: "info" };
      case "unshipped":
        return { text: "待出貨", variant: "primary" };
      case "shipped":
        return { text: "已出貨", variant: "success" };
      case "cancelled":
        return { text: "已取消", variant: "danger" };
      default:
        return { text: "未知", variant: "secondary" };
    }
  };

  // 付款方式對應的中文名稱
  const getPaymentTypeDisplay = (type: string) => {
    switch (type) {
      case "newebpay":
        return "線上付款";
      case "cod":
        return "貨到付款";
      default:
        return "未知";
    }
  };

  // 格式化日期
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("zh-TW", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center my-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">載入中...</span>
        </Spinner>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center bg-light p-3">
        <h2 className="text-danger">載入訂單時發生錯誤</h2>
        <p>請稍後再試或聯繫客服。</p>
      </div>
    );
  }

  const orders = data?.orders || [];
  const totalCount = data?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <div>
      {orders.length === 0 ? (
        <>
          <div className="d-flex flex-column justify-content-center align-items-center bg-light p-3">
            <h2 className="flex-grow-1">您沒有任何訂單</h2>
            <p>您在目前沒有任何訂單。</p>
            <Button
              variant="primary"
              onClick={() => {
                window.location.href = "/micro-business-solutions-store";
              }}
            >
              繼續購物
            </Button>
          </div>
          <p className="text-center py-3">僅顯示2年內訂單</p>
        </>
      ) : (
        <div className="my-3">
          <h2 className="mb-4">我的訂單</h2>
          {orders.map((order) => (
            <Card
              key={order.id}
              className="mb-3 order-card"
              onClick={() => handleOrderClick(order.id)}
              style={{ cursor: "pointer" }}
            >
              <Card.Header className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="mb-0">訂單編號: {order?.displayId}</p>
                  <small className="text-muted">
                    訂單日期: {formatDate(order?.createdAt?.toString() || "")}
                  </small>
                </div>
                <Badge bg={getStatusDisplay(order?.status || "").variant}>
                  {getStatusDisplay(order?.status || "").text}
                </Badge>
              </Card.Header>
              <Card.Body>
                <div className="row">
                  <div className="col-md-6">
                    <p className="mb-1">
                      <strong>付款方式:</strong>{" "}
                      {getPaymentTypeDisplay(order?.paymentType || "")}
                    </p>
                    <p className="mb-1">
                      <strong>聯絡人:</strong> {order?.contactName}
                    </p>
                    <p className="mb-1">
                      <strong>聯絡電話:</strong> {order?.contactPhone}
                    </p>
                  </div>
                  <div className="col-md-6 text-md-end">
                    <p className="mb-1">
                      <strong>總金額:</strong> NT$
                      {order?.totalAmountWithTax?.toLocaleString()}
                    </p>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="mt-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOrderClick(order.id);
                      }}
                    >
                      查看詳情
                    </Button>
                  </div>
                </div>

                {/* 訂單商品預覽 */}
                <div className="mt-3">
                  <small className="text-muted">商品項目:</small>
                  <div className="d-flex flex-wrap mt-1">
                    {order?.orderItems?.slice(0, 3).map((item, index) => (
                      <span key={item.id} className="me-2 mb-1">
                        {item.productName} x {item.quantity}
                        {index < Math.min(order?.orderItems?.length || 0, 3) - 1
                          ? ","
                          : ""}
                      </span>
                    ))}
                    {order?.orderItems && order.orderItems.length > 3 && (
                      <span className="text-muted">
                        ...等 {order.orderItems.length} 項商品
                      </span>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          ))}

          {totalPages > 1 && (
            <div className="mt-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
