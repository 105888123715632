// invoke api to create a contact request
import axios from 'axios';

export interface ContactRequest {
    name: string;
    email: string;
    phone: string;
    message: string;
    reference?: string;
    merchantId?: number; // will be added by the lib
}

export const createContactRequest = async (contactRequest: ContactRequest) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const MERCHANT_ID = parseInt(process.env.REACT_APP_API_MERCHANT_ID!);
    contactRequest.merchantId = MERCHANT_ID;
    const response = await axios.post(`${API_URL}/contact-requests`, contactRequest);
    return response.data;
};