import { create } from "zustand";
import { ListCartItemResponse } from "../api/Cart/Cart";

export type CartStore = {
  cart: ListCartItemResponse[];
  loadCart: (cartItems: ListCartItemResponse[]) => void;
  resetCart: () => void;
};

export const useCartStore = create<CartStore>((set, get) => ({
  cart: [],
  loadCart: (cartItems: ListCartItemResponse[]) => {
    set({ cart: cartItems });
  },
  resetCart: () => {
    set({ cart: [] });
  },
}));
