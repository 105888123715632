// CartDropdown.tsx
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaTrash } from "react-icons/fa";
import { useCartManagement } from "../hooks/useCartManagement";
import { useCartStore } from "../stores/Cart";
import { useListCartQuery } from "../hooks/Cart";
import LoginRequiredModal from "./LoginRequiredModal";
import { useTokenStore } from "../stores/Token";
import { useNavigate } from "react-router-dom";

const CartDropdown = () => {
  const { data: cartItems, isLoading: isCartLoading } = useListCartQuery();
  const userToken = useTokenStore((state) => state.value);
  const navigate = useNavigate();

  useEffect(() => {
    if (isCartLoading || !cartItems) return;

    if (cartItems) {
      useCartStore.setState({ cart: cartItems });
    }
  }, [cartItems, isCartLoading]);

  const cart = useCartStore((state) => state.cart);
  const { removeFromCart, showLoginModal, setShowLoginModal } =
    useCartManagement();

  const handleRemoveItem = (itemId: number) => {
    removeFromCart(itemId);
  };

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  const handleCartClick = () => {
    if (!userToken) {
      setShowLoginModal(true);
    } else {
      window.location.href = "/cart";
    }
  };

  return (
    <div>
      <div style={{ marginBottom: 5 }}>
        <span>購物車</span>
      </div>
      {/* empty cart */}
      {(!cart || cart.length === 0) && (
        <div style={{ textAlign: "center", padding: 15, color: "gray" }}>
          購物車是空的
        </div>
      )}
      {/* items */}
      {cart &&
        cart.map((item, index) => (
          <div
            key={index}
            className="pt-2"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", minHeight: 80 }}
            >
              <img
                src={
                  item.product?.productMedias?.[0]?.media?.url ||
                  "https://via.placeholder.com/100"
                }
                alt={item.product?.name || "商品"}
                style={{
                  maxWidth: 100,
                  maxHeight: 80,
                  width: "auto",
                  height: "100%",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                  justifyContent: "space-around",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  height: 72,
                }}
              >
                <span>{item.product?.name || "未知商品"}</span>
                <span style={{ fontWeight: 400, fontSize: 14, marginTop: 5 }}>
                  {item.quantity} * NT$
                  {item.product?.price?.toLocaleString() || "0"}
                </span>
              </div>
            </div>
            <div>
              <FaTrash
                style={{ width: 15, height: 15, cursor: "pointer" }}
                onClick={() => handleRemoveItem(item.id)}
              />
            </div>
          </div>
        ))}
      {/* checkout button */}
      <div
        style={{
          cursor: "pointer",
          marginTop: 15,
          padding: 15,
          backgroundColor: "#333",
          textAlign: "center",
          color: "#fff",
        }}
        onClick={handleCartClick}
      >
        購物車
      </div>

      {/* 登入提示彈窗 */}
      <LoginRequiredModal
        show={showLoginModal}
        onClose={handleCloseLoginModal}
      />
    </div>
  );
};

export default CartDropdown;
