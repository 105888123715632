import React from "react";
import "../styles/CallToActioTwo.css";

const CallToActionTwo = ({ title, subtitle, buttonText, buttonLink }) => {
  const handleClick = (e) => {
    e.preventDefault(); // Prevent immediate navigation

    // Debug logging
    console.log("Button clicked");
    console.log("dataLayer available:", !!window.dataLayer);

    // Try both methods to ensure the event is sent
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "立即聯繫",
        event_category: "engagement",
        event_label: buttonText,
      });
      console.log("Event pushed to dataLayer");
    }

    if (window.gtag) {
      window.gtag("event", "立即聯繫", {
        event_category: "engagement",
        event_label: buttonText,
      });
      console.log("Event sent via gtag");
    }

    // Navigate after sending the event
    window.location.href = buttonLink;
  };

  return (
    <section className="CallToAction text-center py-5">
      <div className="container">
        <h2>{title}</h2>
        <p dangerouslySetInnerHTML={{ __html: subtitle }}></p>
        <div className="btn-group">
          <a
            href={buttonLink}
            className="btn btn-light btn-lg rounded-pill"
            onClick={handleClick}
          >
            {buttonText}
          </a>
        </div>
      </div>
    </section>
  );
};

export default CallToActionTwo;
