import React, { useEffect, useState } from "react";
import "../styles/Pricing.css";
import PricingSwitcher from "../components/PricingSwitcher";
import PricingSwitcherTwo from "../components/PricingSwitcherTwo";
import BannerSection from "../components/BannerSection";
import PricingBanner from "../assets/pricing-banner.png";
import { useFindProductsByCategoryIdAndPaymentTypeQuery } from "../hooks/Product";
import { Helmet } from "react-helmet";

const Pricing = () => {
  const [pricingData, setPricingData] = useState({});
  const { data: brandedWebsiteMonthly, isFetched } =
    useFindProductsByCategoryIdAndPaymentTypeQuery(
      +process.env.REACT_APP_API_BRANDED_WEBSITE_CATEGORY_ID!, // the mainCategoryId for '形象網站'
      "SUBSCRIPTION_MONTHLY"
    );

  const {
    data: brandedWebsiteAnnually,
    isFetched: isBrandedWebsiteAnnuallyFetched,
  } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_BRANDED_WEBSITE_CATEGORY_ID!, // the mainCategoryId for '形象網站'
    "SUBSCRIPTION_ANNUALLY"
  );

  const [pricingDataTwo, setPricingDataTwo] = useState({});
  const {
    data: shoppingWebsiteMonthly,
    isFetched: isShoppingWebsiteMonthlyFetched,
  } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_SHOPPING_WEBSITE_CATEGORY_ID!, // the mainCategoryId for '購物網站'
    "SUBSCRIPTION_MONTHLY"
  );

  const {
    data: shoppingWebsiteAnnually,
    isFetched: isShoppingWebsiteAnnuallyFetched,
  } = useFindProductsByCategoryIdAndPaymentTypeQuery(
    +process.env.REACT_APP_API_SHOPPING_WEBSITE_CATEGORY_ID!, // the mainCategoryId for '購物網站'
    "SUBSCRIPTION_ANNUALLY"
  );

  useEffect(() => {
    if (!isFetched) {
      return;
    }
    if (brandedWebsiteMonthly === undefined) {
      return;
    }
    // convert the format
    const formattedData = brandedWebsiteMonthly.map((product) => {
      return {
        title: product.name,
        price: product.price === 0 ? "議定" : product.price,
        annualPrice: product.originalPrice === 0 ? "" : product.originalPrice, // use originalPrice as annual price here
        description: product.slogan,
        period: "/month",
        tooltipContent:
          product.name !== "客製化方案" ? ( // use tooltipContent for '客製化方案' as '請聯絡我們'
            <ul>
              <li>方案僅限年繳</li>
              <li>另需收取一次性系統開通設定費NT$5,000</li>
              <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
            </ul>
          ) : (
            "請聯絡我們"
          ),
        features: product.description,
        notIncluded: [""],
      };
    });
    setPricingData((prevData) => ({
      ...prevData,
      monthly: formattedData,
    }));
  }, [brandedWebsiteMonthly, isFetched]);

  useEffect(() => {
    if (!isBrandedWebsiteAnnuallyFetched) {
      return;
    }
    if (brandedWebsiteAnnually === undefined) {
      return;
    }
    // convert the format
    const formattedData = brandedWebsiteAnnually.map((product) => {
      return {
        title: product.name,
        price: product.price === 0 ? "議定" : product.price,
        originalPrice: product.originalPrice === 0 ? "" : product.originalPrice, // use originalPrice as annual price here
        description: product.slogan,
        period: "/year",
        tooltipContent:
          product.name !== "客製化方案" ? ( // use tooltipContent for '客製化方案' as '請聯絡我們'
            <ul>
              <li>方案僅限年繳</li>
              <li>另需收取一次性系統開通設定費NT$5,000</li>
              <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
            </ul>
          ) : (
            "請聯絡我們"
          ),
        features: product.description,
        notIncluded: [""],
      };
    });
    setPricingData((prevData) => ({
      ...prevData,
      annual: formattedData,
    }));
  }, [brandedWebsiteAnnually, isBrandedWebsiteAnnuallyFetched]);

  useEffect(() => {
    if (!isShoppingWebsiteMonthlyFetched) {
      return;
    }
    if (shoppingWebsiteMonthly === undefined) {
      return;
    }
    // convert the format
    const formattedData = shoppingWebsiteMonthly.map((product) => {
      return {
        title: product.name,
        price: product.price === 0 ? "議定" : product.price,
        annualPrice: product.originalPrice === 0 ? "" : product.originalPrice, // use originalPrice as annual price here
        description: product.slogan,
        period: "/month",
        tooltipContent:
          product.name !== "客製化方案" ? ( // use tooltipContent for '客製化方案' as '請聯絡我們'
            <ul>
              <li>方案僅限年繳</li>
              <li>另需收取一次性系統開通設定費NT$5,000</li>
              <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
            </ul>
          ) : (
            "請聯絡我們"
          ),
        features: product.description,
        notIncluded: [""],
      };
    });
    setPricingDataTwo((prevData) => ({
      ...prevData,
      monthly: formattedData,
    }));
  }, [shoppingWebsiteMonthly, isShoppingWebsiteMonthlyFetched]);

  useEffect(() => {
    if (!isShoppingWebsiteAnnuallyFetched) {
      return;
    }
    if (shoppingWebsiteAnnually === undefined) {
      return;
    }
    // convert the format
    const formattedData = shoppingWebsiteAnnually.map((product) => {
      return {
        title: product.name,
        price: product.price === 0 ? "議定" : product.price,
        originalPrice: product.originalPrice === 0 ? "" : product.originalPrice, // use originalPrice as annual price here
        description: product.slogan,
        period: "/year",
        tooltipContent:
          product.name !== "客製化方案" ? ( // use tooltipContent for '客製化方案' as '請聯絡我們'
            <ul>
              <li>方案僅限年繳</li>
              <li>另需收取一次性系統開通設定費NT$5,000</li>
              <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
            </ul>
          ) : (
            "請聯絡我們"
          ),
        features: product.description,
        notIncluded: [""],
      };
    });
    setPricingDataTwo((prevData) => ({
      ...prevData,
      annual: formattedData,
    }));
  }, [shoppingWebsiteAnnually, isShoppingWebsiteAnnuallyFetched]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="pricing-section">
      <Helmet>
        <title>實惠的網站和電商平台定價計劃 - Big-O</title>
        <meta
          name="description"
          content="探索 Big-O 為品牌和購物網站提供的實惠定價計劃。選擇適合您業務需求的月度或年度訂閱。"
        />
        <meta
          name="keywords"
          content="定價計劃, Big-O, 月度訂閱, 年度訂閱, 電商平台定價, 網站定價"
        />
      </Helmet>
      <div>
        <BannerSection
          category="AI永續智能電商 - 微型商務解決方案"
          title="無牆開店，超乎你我想像"
          subtitle='我們提供形象網站及購物平台的不同方案，讓您有更多選擇，不知道自己適合哪一種方案嗎？請<a href="/contact">聯繫我們</a>'
          backgroundImage={PricingBanner}
          titleStyle={{}}
        />
      </div>
      <div className="">
        <div className="text-center mb-4">
          <ul
            className="nav nav-pills justify-content-center"
            id="pricing-tabs"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                id="monthly-tab"
                data-bs-toggle="pill"
                href="#monthly"
                role="tab"
              >
                形象網站
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="annual-tab"
                data-bs-toggle="pill"
                href="#annual"
                role="tab"
              >
                購物網站
              </a>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="monthly"
            role="tabpanel"
          >
            <div className="container">
              <div className="row">
                <PricingSwitcher pricingData={pricingData} />
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="annual" role="tabpanel">
            <div className="container">
              <div className="row">
                <PricingSwitcherTwo pricingData={pricingDataTwo} />{" "}
                {/* Corrected the reference */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
