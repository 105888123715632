import BannerSection from "../components/BannerSection";
import bannerImage from "../assets/Checkout-banner2.jpg";
import { useListCartQuery } from "../hooks/Cart";
import { useCartManagement } from "../hooks/useCartManagement";
import { useCartStore } from "../stores/Cart";
import { useShallow } from "zustand/react/shallow";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTokenStore } from "../stores/Token";
import { useUpdateCartItemMutation } from "../hooks/Cart";
const Cart = () => {
  const { data: cartItems, isLoading: isCartLoading } = useListCartQuery();
  const { cart, loadCart } = useCartStore(
    useShallow((state) => ({
      cart: state.cart,
      loadCart: state.loadCart,
    }))
  );
  const navigate = useNavigate();
  const userToken = useTokenStore((state) => state.value);

  useEffect(() => {
    // 如果用戶未登入，重定向到登入頁面
    if (!userToken) {
      navigate("/login", { state: { from: "/cart" } });
    }
  }, [userToken, navigate]);

  useEffect(() => {
    if (isCartLoading || !cartItems) return;

    loadCart(cartItems);
  }, [cartItems, loadCart, isCartLoading]);

  const { removeFromCart } = useCartManagement();
  const updateCartItemMutation = useUpdateCartItemMutation();
  const handleUpdateItem = async (itemId: number, quantity: number) => {
    const existingItem = cart.find((cartItem) => cartItem.id === itemId);

    if (!existingItem) {
      return;
    }

    await updateCartItemMutation.mutateAsync({
      cartItemId: existingItem.id,
      productId: existingItem.productId,
      quantity,
    });
  };
  const handleRemoveItem = (itemId: number) => {
    removeFromCart(itemId);
  };

  // 如果用戶未登入，不渲染任何內容（因為會被重定向）
  if (!userToken) {
    return null;
  }

  // 如果購物車為空，顯示空購物車訊息
  if (!isCartLoading && (!cart || cart.length === 0)) {
    return (
      <>
        <BannerSection
          category=""
          title="購物車"
          subtitle=""
          backgroundImage={bannerImage}
          titleStyle={{ textAlign: "center", width: "100%" }}
        />
        <section className="py-5">
          <div className="container text-center">
            <h4>您的購物車是空的</h4>
            <p>請添加商品到購物車</p>
            <button
              className="btn btn-primary mt-3"
              onClick={() =>
                (window.location.href = "/micro-business-solutions-store")
              }
            >
              繼續購物
            </button>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <BannerSection
        category=""
        title="購物車"
        subtitle=""
        backgroundImage={bannerImage}
        titleStyle={{ textAlign: "center", width: "100%" }}
      />
      <section className="py-5">
        <div className="container d-md-flex">
          {/* product list */}
          <div className="flex-grow-1">
            <h4
              style={{
                width: "fit-content",
                paddingRight: 10,
                position: "relative",
                fontWeight: 400,
                zIndex: 2,
              }}
            >
              商品清單
            </h4>
            {cart &&
              cart.length > 0 &&
              cart.map((item, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    marginTop: 15,
                    padding: 15,
                    backgroundColor: "#f7f5fa",
                    borderRadius: 5,
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ flex: 2 }}
                  >
                    <img
                      className="d-none d-md-block"
                      src={
                        item?.product?.productMedias &&
                        item.product.productMedias.length > 0
                          ? item.product.productMedias[0]?.media?.url ||
                            "https://via.placeholder.com/100"
                          : "https://via.placeholder.com/100"
                      }
                      alt="product"
                      style={{
                        width: 100,
                        height: 100,
                        objectFit: "cover",
                        borderRadius: 5,
                      }}
                    />
                    <div className="ms-3">
                      <h5 style={{ fontWeight: 400, fontSize: 16 }}>
                        {item?.product?.name || "未知商品"}
                      </h5>
                      <span style={{ fontWeight: 400, fontSize: 14 }}>
                        NT${" "}
                        {item?.product?.price
                          ? item.product.price.toLocaleString()
                          : "0"}
                      </span>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        cursor: "pointer",
                        color: "gray",
                      }}
                      onClick={() => {
                        if (item && item.quantity > 0) {
                          console.log("item", item);
                          handleUpdateItem(item.id, item.quantity - 1);
                          if (item.quantity === 1) {
                            handleRemoveItem(item.id);
                          }
                        }
                      }}
                    >
                      <i className="fas fa-minus"></i>
                    </span>
                    <span style={{ marginLeft: 5, marginRight: 5 }}>
                      {item?.quantity || 0}
                    </span>
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        cursor: "pointer",
                        color: "gray",
                      }}
                      onClick={() => {
                        if (item) {
                          handleUpdateItem(item.id, (item.quantity || 0) + 1);
                        }
                      }}
                    >
                      <i className="fas fa-plus"></i>
                    </span>
                  </div>
                  <div style={{ flex: 1 }}>
                    <span style={{ marginLeft: 15 }}>
                      NT${" "}
                      {item?.product?.price && item.quantity
                        ? (item.product.price * item.quantity).toLocaleString()
                        : "0"}
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ marginLeft: 15, cursor: "pointer" }}
                      onClick={() => item && handleRemoveItem(item.id)}
                    >
                      <i className="fas fa-trash"></i>
                    </span>
                  </div>
                </div>
              ))}
            {cart && cart.length > 0 && (
              <div
                style={{
                  cursor: "pointer",
                  marginTop: 15,
                  padding: 15,
                  backgroundColor: "#333",
                  textAlign: "center",
                  color: "#fff",
                }}
                onClick={() => {
                  window.location.href = "/checkout";
                }}
              >
                訂單結帳
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
