import { batchRemoveCartItems, ListCartItemResponse } from "../api/Cart/Cart";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addItemToCart,
  clearCart,
  listCart,
  removeCartItem,
  updateCartItem,
} from "../api/Cart/Cart";
import { useTokenStore } from "../stores/Token";
import { Product } from "../api/Product/Product";

export function useListCartQuery() {
  const userToken = useTokenStore((state) => state.value);

  return useQuery({
    queryKey: ["cart"],
    queryFn: () => listCart({ userToken }),
    enabled: !!userToken,
  });
}

export function useAddItemToCartMutation() {
  const userToken = useTokenStore((state) => state.value);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (newItem: { productId: number; quantity: number }) =>
      addItemToCart({ ...newItem, userToken }),
    onMutate: async (newItem) => {
      // 取消任何正在進行的查詢，以避免它們覆蓋我們的樂觀更新
      await queryClient.cancelQueries({ queryKey: ["cart"] });

      // 保存之前的購物車狀態，以便在錯誤時回滾
      const previousCart = queryClient.getQueryData<ListCartItemResponse[]>([
        "cart",
      ]);

      // 樂觀地更新購物車
      if (previousCart) {
        // 創建一個新的購物車項目
        const optimisticItem: ListCartItemResponse = {
          id: Date.now(), // 臨時 ID
          productId: newItem.productId,
          quantity: newItem.quantity,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          customerId: 0, // 臨時值
          product: (previousCart.find(
            (item) => item.productId === newItem.productId
          )?.product || {}) as Product,
        };

        queryClient.setQueryData<ListCartItemResponse[]>(
          ["cart"],
          [...previousCart, optimisticItem]
        );
      }

      // 返回上下文對象，其中包含之前的購物車，以便在錯誤時回滾
      return { previousCart };
    },
    onError: (err, newItem, context) => {
      // 如果發生錯誤，回滾到之前的購物車狀態
      if (context?.previousCart) {
        queryClient.setQueryData<ListCartItemResponse[]>(
          ["cart"],
          context.previousCart
        );
      }
    },
    onSettled: () => {
      // 無論成功還是失敗，都重新獲取購物車數據
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
  });
}

export function useUpdateCartItemMutation() {
  const userToken = useTokenStore((state) => state.value);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (updatedItem: {
      cartItemId: number;
      productId: number;
      quantity: number;
    }) => updateCartItem({ ...updatedItem, userToken }),
    onMutate: async (updatedItem) => {
      // 取消任何正在進行的查詢
      await queryClient.cancelQueries({ queryKey: ["cart"] });

      // 保存之前的購物車狀態
      const previousCart = queryClient.getQueryData<ListCartItemResponse[]>([
        "cart",
      ]);

      // 樂觀地更新購物車
      if (previousCart) {
        const updatedCart = previousCart.map((item) => {
          if (item.productId === updatedItem.productId) {
            return { ...item, quantity: updatedItem.quantity };
          }
          return item;
        });

        queryClient.setQueryData<ListCartItemResponse[]>(["cart"], updatedCart);
      }

      return { previousCart };
    },
    onError: (err, updatedItem, context) => {
      // 如果發生錯誤，回滾到之前的購物車狀態
      if (context?.previousCart) {
        queryClient.setQueryData<ListCartItemResponse[]>(
          ["cart"],
          context.previousCart
        );
      }
    },
    onSettled: () => {
      // 無論成功還是失敗，都重新獲取購物車數據
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
  });
}

export function useRemoveCartItemMutation() {
  const userToken = useTokenStore((state) => state.value);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (cartItemIds: number) =>
      removeCartItem({ cartItemIds, userToken }),
    onMutate: async (cartItemIds) => {
      // 取消任何正在進行的查詢
      await queryClient.cancelQueries({ queryKey: ["cart"] });

      // 保存之前的購物車狀態
      const previousCart = queryClient.getQueryData<ListCartItemResponse[]>([
        "cart",
      ]);

      // 樂觀地更新購物車
      if (previousCart) {
        const updatedCart = previousCart.filter(
          (item) => item.id !== cartItemIds
        );
        queryClient.setQueryData<ListCartItemResponse[]>(["cart"], updatedCart);
      }

      return { previousCart };
    },
    onError: (err, productId, context) => {
      // 如果發生錯誤，回滾到之前的購物車狀態
      if (context?.previousCart) {
        queryClient.setQueryData<ListCartItemResponse[]>(
          ["cart"],
          context.previousCart
        );
      }
    },
    onSettled: () => {
      // 無論成功還是失敗，都重新獲取購物車數據
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
  });
}

export function useClearCartMutation() {
  const userToken = useTokenStore((state) => state.value);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => clearCart({ userToken }),
    onMutate: async () => {
      // 取消任何正在進行的查詢
      await queryClient.cancelQueries({ queryKey: ["cart"] });

      // 保存之前的購物車狀態
      const previousCart = queryClient.getQueryData<ListCartItemResponse[]>([
        "cart",
      ]);

      // 樂觀地清空購物車
      queryClient.setQueryData<ListCartItemResponse[]>(["cart"], []);

      return { previousCart };
    },
    onError: (err, variables, context) => {
      // 如果發生錯誤，回滾到之前的購物車狀態
      if (context?.previousCart) {
        queryClient.setQueryData<ListCartItemResponse[]>(
          ["cart"],
          context.previousCart
        );
      }
    },
    onSettled: () => {
      // 無論成功還是失敗，都重新獲取購物車數據
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
  });
}

export function useBatchRemoveCartItemsMutation() {
  const userToken = useTokenStore((state) => state.value);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (cartItemIds: number[]) =>
      batchRemoveCartItems({ cartItemIds, userToken }),
    onMutate: async (cartItemIds) => {
      // 取消任何正在進行的查詢
      await queryClient.cancelQueries({ queryKey: ["cart"] });

      // 保存之前的購物車狀態
      const previousCart = queryClient.getQueryData<ListCartItemResponse[]>([
        "cart",
      ]);

      // 樂觀地更新購物車
      if (previousCart) {
        const updatedCart = previousCart.filter(
          (item) => !cartItemIds.includes(item.id)
        );
        queryClient.setQueryData<ListCartItemResponse[]>(["cart"], updatedCart);
      }

      return { previousCart };
    },
    onError: (err, cartItemIds, context) => {
      // 如果發生錯誤，回滾到之前的購物車狀態
      if (context?.previousCart) {
        queryClient.setQueryData<ListCartItemResponse[]>(
          ["cart"],
          context.previousCart
        );
      }
    },
    onSettled: () => {
      // 無論成功還是失敗，都重新獲取購物車數據
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
  });
}
