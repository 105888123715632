import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Card, Table, Badge, Spinner, Row, Col } from "react-bootstrap";
import { useGetCustomerOrderDetailQuery } from "../hooks/Order";
import { Order } from "../api/Order/Order";

export function OrderDetail() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const orderId = id ? parseInt(id) : 0;

  const {
    data: order,
    isLoading,
    isError,
  } = useGetCustomerOrderDetailQuery(orderId);

  // 訂單狀態對應的中文名稱和樣式
  const getStatusDisplay = (status: string) => {
    switch (status) {
      case "open":
        return { text: "待處理", variant: "warning" };
      case "processing":
        return { text: "處理中", variant: "info" };
      case "unshipped":
        return { text: "待出貨", variant: "primary" };
      case "shipped":
        return { text: "已出貨", variant: "success" };
      case "cancelled":
        return { text: "已取消", variant: "danger" };
      default:
        return { text: "未知", variant: "secondary" };
    }
  };

  // 付款方式對應的中文名稱
  const getPaymentTypeDisplay = (type: string) => {
    switch (type) {
      case "newebpay":
        return "線上付款";
      case "cod":
        return "貨到付款";
      default:
        return "未知";
    }
  };

  const getChargeTypeDisplay = (type: string) => {
    switch (type) {
      case "buyout":
        return "一次性付款";
      case "monthly":
        return "每月";
      case "annual":
        return "每年";
      case "monthly_and_annual":
        return "每月+每年";
      default:
        return "未知";
    }
  };

  // 格式化日期
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("zh-TW", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center my-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">載入中...</span>
        </Spinner>
      </div>
    );
  }

  if (isError || !order) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center bg-light p-3">
        <h2 className="text-danger">載入訂單詳情時發生錯誤</h2>
        <p>請稍後再試或聯繫客服。</p>
        <Button variant="primary" onClick={() => navigate("/member/order")}>
          返回訂單列表
        </Button>
      </div>
    );
  }

  return (
    <div className="my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>訂單詳情</h2>
        <Button
          variant="outline-secondary"
          onClick={() => navigate("/member/order")}
        >
          返回訂單列表
        </Button>
      </div>

      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="mb-0">訂單編號: {order?.displayId}</h5>
          </div>
          <Badge
            bg={getStatusDisplay(order?.status || "").variant}
            className="p-2"
          >
            {getStatusDisplay(order?.status || "").text}
          </Badge>
        </Card.Header>
        <Card.Body>
          {/* 訂單進度指示器 */}
          <div className="position-relative mb-4" style={{ padding: "20px 0" }}>
            <div className="d-flex justify-content-between align-items-center position-relative">
              {/* 背景進度線 */}
              <div
                className="position-absolute"
                style={{
                  height: "2px",
                  backgroundColor: "#e0e0e0",
                  width: "100%",
                  top: "15px",
                }}
              />

              {["open", "processing", "unshipped", "shipped"].map(
                (status, index) => {
                  const isActive =
                    ["open", "processing", "unshipped", "shipped"].indexOf(
                      order.status
                    ) >= index;
                  return (
                    <div
                      key={status}
                      className="d-flex flex-column align-items-center"
                      style={{ zIndex: 2 }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          backgroundColor: isActive ? "#27ae60" : "#e0e0e0",
                          color: "white",
                          fontWeight: "bold",
                          marginBottom: "6px",
                        }}
                      >
                        {isActive ? "✓" : ""}
                      </div>
                      <small className={isActive ? "text-dark" : "text-muted"}>
                        {(() => {
                          switch (status) {
                            case "open":
                              return "已成立";
                            case "processing":
                              return "已付款";
                            case "unshipped":
                              return "待架設";
                            case "shipped":
                              return "已完成";
                            default:
                              return "未知";
                          }
                        })()}
                      </small>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <Row className="mb-4">
            <Col md={6}>
              <h6>訂單資訊</h6>
              <p className="mb-1">
                <strong>訂單日期:</strong>{" "}
                {formatDate(order?.createdAt?.toString() || "")}
              </p>
              <p className="mb-1">
                <strong>更新日期:</strong>{" "}
                {formatDate(order?.updatedAt?.toString() || "")}
              </p>
              <p className="mb-1">
                <strong>付款方式:</strong>{" "}
                {getPaymentTypeDisplay(order?.paymentType || "")}
              </p>
              {order?.comment && (
                <p className="mb-1">
                  <strong>備註:</strong> {order.comment}
                </p>
              )}
            </Col>
            <Col md={6}>
              <h6>聯絡資訊</h6>
              <p className="mb-1">
                <strong>聯絡人:</strong> {order?.contactName}
              </p>
              <p className="mb-1">
                <strong>聯絡電話:</strong> {order?.contactPhone}
              </p>
              <p className="mb-1">
                <strong>聯絡信箱:</strong> {order?.contactEmail}
              </p>
            </Col>
          </Row>

          <h6 className="mb-3">訂單金額</h6>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>項目</th>
                <th className="text-end">金額</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>商品總額 (未稅)</td>
                <td className="text-end">
                  NT${order?.totalAmountWithoutTax?.toLocaleString()}
                </td>
              </tr>
              {order?.chargeType === "monthly" ||
              order?.chargeType === "monthly_and_annual" ? (
                <tr>
                  <td>月費總額 (未稅)</td>
                  <td className="text-end">
                    NT${order?.totalMonthlyAmountWithoutTax?.toLocaleString()}
                  </td>
                </tr>
              ) : null}
              {order?.chargeType === "annual" ||
              order?.chargeType === "monthly_and_annual" ? (
                <tr>
                  <td>年費總額 (未稅)</td>
                  <td className="text-end">
                    NT${order?.totalAnnualAmountWithoutTax?.toLocaleString()}
                  </td>
                </tr>
              ) : null}
              <tr>
                <td>
                  稅額 ({(order?.taxRate ? order.taxRate * 100 : 0).toFixed(0)}
                  %)
                </td>
                <td className="text-end">
                  NT$
                  {(order?.totalAmountWithTax && order?.totalAmountWithoutTax
                    ? order.totalAmountWithTax - order.totalAmountWithoutTax
                    : 0
                  ).toLocaleString()}
                </td>
              </tr>
              {order?.deliveryFee > 0 && (
                <tr>
                  <td>運費</td>
                  <td className="text-end">
                    NT${order?.deliveryFee?.toLocaleString()}
                  </td>
                </tr>
              )}
              <tr className="table-active">
                <td>
                  <strong>總計</strong>
                </td>
                <td className="text-end">
                  <strong>
                    NT${order?.totalAmountWithTax?.toLocaleString()}
                  </strong>
                </td>
              </tr>
            </tbody>
          </Table>

          <h6 className="mb-3 mt-4">訂單商品</h6>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>商品名稱</th>
                <th>單價</th>
                <th>數量</th>
                <th className="text-end">小計</th>
              </tr>
            </thead>
            <tbody>
              {order?.orderItems?.map((item) => (
                <tr key={item.id}>
                  <td>
                    <div className="d-flex align-items-center">
                      {item.productImage && (
                        <img
                          src={item.productImage}
                          alt={item.productName}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                            marginRight: "10px",
                          }}
                          onError={(e) => {
                            // 圖片載入失敗時顯示預設圖片
                            e.currentTarget.src =
                              "https://via.placeholder.com/50";
                          }}
                        />
                      )}
                      <div>
                        <p className="mb-0">{item.productName}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    NT${item.productPrice?.toLocaleString()}
                    {item.productOriginalPrice > item.productPrice && (
                      <div>
                        <small className="text-muted text-decoration-line-through">
                          NT${item.productOriginalPrice?.toLocaleString()}
                        </small>
                      </div>
                    )}
                  </td>
                  <td>{item.quantity}</td>
                  <td className="text-end">
                    NT${(item.productPrice * item.quantity)?.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h6 className="mb-3 mt-4">付款紀錄</h6>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>產生時間</th>
                <th>付款方式</th>
                <th>請款週期</th>
                <th>付款日期</th>
                <th>付款金額</th>
              </tr>
            </thead>
            <tbody>
              {order.payments.map((payment) => (
                <tr key={payment.id}>
                  <td>{formatDate(payment.createdAt)}</td>
                  <td>{getPaymentTypeDisplay(payment.paymentType)}</td>
                  <td>{getChargeTypeDisplay(payment.chargeType)}</td>
                  <td>
                    {payment.paidAt ? formatDate(payment.paidAt) : "未付款"}
                  </td>
                  <td>NT${payment.amount?.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}
